<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/cases' }"
          >合作案例</el-breadcrumb-item
        >

        <el-breadcrumb-item>{{
          title.substring(0, 6) + "..."
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box" v-if="id == 1">
        <!-- <div class="img_box">
          <img src="../../assets/img/detail/detail2.png" alt="" />
        </div> -->
        <p>
          电子函证管理系统与CFCA会银通协同，是面向城商行、信用社函证业务的全方位、无死角、全流程服务的函证管理平台。可实现函证全流程、全环节管控，有效地控制函证风险，提高了函证出函效率、降低了函证成本。具备完善的函证业务处理和平台运营管理功能，从函证生成到回函归档，实现了全流程的业务自动化和智能化，强化审计功能，保证事前数据治理准确、事中出函合规、事后智能回溯，精准识别函证风险。实现协同、高效、合规、风控的设计目标。
        </p>
        <p>
          通过对该系统的建设，为多家银行贯彻落实财政部、银保监会、人民银行等监管部门的改革要求，同时优化函证业务办理流程，提升回函服务质效，防范业务风险，推动银行函证业务流程规范化、路径电子化、运营管理集约化、数据治理精细化转型。
        </p>
        <p>
          系统整体基于SOA面向服务思想，采用微服务架构技术，遵循服务化、原子化、模块化的设计思路，实现项目的高扩展性和便捷性，有利于后期系统的扩展以及运维。并通过业务拆分实现单个业务模块，实现系统的局部升级，在系统后期运维及升级中，能大大提高效率并降低系统风险。采用微服务模式，大大的提高了系统的升级扩展能力。同时系统基于负载均衡模式下使用，提高了系统的易维护性功能，达到无感知项目替换的目的。
        </p>
      </div>
      <div class="box" v-if="id == 2">
        <!-- <div class="img_box">
          <img src="../../assets/img/detail/detail2.png" alt="" />
        </div> -->
        <p>
          甘肃省联社银企对账“一号密函”对账系统于2021年12月正式上线，系统上线后除原有的对账方式外新增了邮件、微信对账渠道，此次银行“一号密函”对账系统上线降低了运营成本，节约人力和物力成本，实现对账工作的方式多样化、快捷化。为客户提供高效服务，为银行银企对账工作的开展提供有力支持。
        </p>
        <p>
          通过对该系统的建设，为多家银行贯彻落实财政部、银保监会、人民银行等监管部门的改革要求，同时优化函证业务办理流程，提升回函服务质效，防范业务风险，推动银行函证业务流程规范化、路径电子化、运营管理集约化、数据治理精细化转型。
        </p>
        <p>
          系统整体基于SOA面向服务思想，采用微服务架构技术，遵循服务化、原子化、模块化的设计思路，实现项目的高扩展性和便捷性，有利于后期系统的扩展以及运维。并通过业务拆分实现单个业务模块，实现系统的局部升级，在系统后期运维及升级中，能大大提高效率并降低系统风险。采用微服务模式，大大的提高了系统的升级扩展能力。同时系统基于负载均衡模式下使用，提高了系统的易维护性功能，达到无感知项目替换的目的。
        </p>
      </div>
      <div class="box" v-if="id == 3">
        <!-- <div class="img_box">
          <img src="../../assets/img/detail/detail2.png" alt="" />
        </div> -->
        <p>
          电子回单系统采用对数据的数字化处理方式，对银行提供的所有账单、凭单、凭证等进行可视数字化处理，实现对数字回单等可视、可追溯、不可篡改、不可抵赖等。通过数字回单/对账单过程数字化、账单模板化和流程规范化，促进数字回单降本增效，提高银企内部审计、监管水平和效率。基于国密算法、国密体系和经认证的加密硬件，对数字回单文件进行电子化签署、安全投递及安全存证，相比于线下纸质账单流程更可靠、更安全。
        </p>
        <p>
          系统采用预授权和签约共存模式完成电子回单用户的签约功能，将已存在账户提供柜面，手机银行，网银，APP等渠道端接口数字回单签约功能。为签约数字回单的用户，提供对应的业务数据信息。
        </p>
        <p>
          支持与新老核心并存的数字回单体系，根据用户签约时选择的周期，完成对应周期的回单发送。对于新核心，通过与新核心对接，完成对账信息由新核心系统实时生成，完成发送功能。
        </p>
        <p>
          电子回单的整个流程，含签约、变更、撤销、登录等环节对客户的身份信息均作了相关校验，具有较高的安全性，支持通过二维码扫描完成电子回单的验伪功能。
        </p>
      </div>

      <div class="box" v-if="id == 4">
        <p>
          结合教育部发布的《教育信息化2.0行动计划》关于持续推动信息技术与教育技术深度融合的相关要求,
          校园食堂场景可以作为最佳切入点，逐步带入校园场景内的其他支付方案。结合上述政策要求，易联刷脸解决方案可为学校提供信息化建设的有力工具，符合教育部的号召的同时，也满足了校园商户的场景支付需求。
        </p>
        <p>
          K12方案是以“数字校园”建设的中的食堂消费为场景，使用“刷脸消费”新型科技技术，以解决学生在校消费问题、食堂收款管理问题为目标，提供的场景支付解决方案。该方案通过学生家长绑定银行卡、录入学生人脸生物信息完成消费绑定关系，学生在校消费时只需要刷脸、点击确认即可，系统扣除家长绑定的银行卡余额完成消费。家长实时掌握学生在校消费情况，系统生成收款报表，方便学校管理。
        </p>
        <div class="img_box">
          <img src="../../assets/img/cases/detail/k12_1.png" alt="" />
        </div>
        <p>
          我司与某城商行合作，在朔州怀仁巨子职业技术学院落地了智慧校园项目。为银行带来批量开卡2000多张，并为银行带来了稳定的资金沉淀，日均交易额为六万多元。
        </p>
        <div class="img_box">
          <img src="../../assets/img/cases/detail/k12_2.jpg" alt="" />
        </div>
      </div>
      <div class="box" v-if="id == 5">
        <p>
          企业智慧团餐将计算机、大数据、物联网等新技术应用于传统食堂，提高食堂信息化的实现程度。近几年最为火热的人脸识别技术也作为其中一项核心技术应用到了智慧食堂中，其无需携带饭卡，识别速度快，操作简单便捷，仅凭人脸便可轻松通过人脸识别进行快速结算的功能，给智慧食堂提升了超高的效率。
        </p>
        <!-- <p>
          K12方案是以“数字校园”建设的中的食堂消费为场景，使用“刷脸消费”新型科技技术，以解决学生在校消费问题、食堂收款管理问题为目标，提供的场景支付解决方案。该方案通过学生家长绑定银行卡、录入学生人脸生物信息完成消费绑定关系，学生在校消费时只需要刷脸、点击确认即可，系统扣除家长绑定的银行卡余额完成消费。家长实时掌握学生在校消费情况，系统生成收款报表，方便学校管理。
        </p> -->
        <div class="img_box">
          <img src="../../assets/img/cases/detail/tc_1.png" alt="" />
        </div>
        <div class="img_box">
          <img src="../../assets/img/cases/detail/tc_2.png" alt="" />
        </div>
        <p>
          山西食佳青年餐饮服务有限公司，位于山西省晋中开发区大学城产业园区山西智慧科技城。
        </p>
        <p>解决方案：易联团餐刷脸整体解决方案</p>
        <p>合作方式：易联提供设备投放、安装、进件、培训等全流程落地支持。</p>
        <p>
          合作方式：易联提供设备投放、安装、进件、培训等全流程落地支持。
          银行合作价值：该园区覆盖200多家中小企业，通过餐厅项目，可带动该餐企的公户开立。
        </p>
        <div class="img_box">
          <img src="../../assets/img/cases/detail/tc_3.jpg" alt="" />
        </div>
        <p>同煤浙能麻家梁煤业有限责任公司，员工餐厅刷脸就餐项目。</p>
        <p>管理方需求：员工刷脸就餐、刷卡就餐、数据统一管理</p>
        <p>解决方案：易联团餐刷脸整体解决方案</p>
        <p>合作方式：易联提供设备投放、安装、进件、培训等全流程落地支持。</p>
        <p>
          银行合作价值：该大型餐厅项目覆盖企业员工8000人，通过餐厅刷脸系统，可为合作银行配置营销活动，预计为银行带动开卡千余张，并且可提升卡月活
        </p>
      </div>
      <div class="box" v-if="id == 6">
        <p>
          面向餐饮、零售、美业等本地生活服务业商家
          提供软硬一体的SaaS整体解决方案，提供涵盖点单、收银、预定、排队、外卖、报表、后厨管理等消费全流程的效率提升工具，帮助商家实现数字化、智能化升级，最终实现
          “店开天下，客如云来”的愿景。
        </p>
        <div class="img_box">
          <img src="../../assets/img/cases/detail/bdsh_1.png" alt="" />
        </div>
        <div class="img_box">
          <img src="../../assets/img/cases/detail/bdsh_2.png" alt="" />
        </div>
        <p>1.海底捞外送</p>
        <p>
          大型跨省直营火锅品牌海底捞，2016年开启外卖服务，2017年海底捞携手客如云，打造全功能外卖管理系统。
        </p>
        <p>
          客如云智能外卖系统同时对接多渠道订餐，并支持后台数据沉淀，记录用户订单量、订单金额、口味偏好、联系方式及地址信息，
          拉动会员转化并进行精准营销。此外，客如云为海底捞外送定制了配送员APP接单，实时计算距离，统计薪酬的功能。
        </p>
        <p>2.奉茶</p>
        <p>
          “奉茶”——新茶饮的引领者，不仅是全球连锁的茶饮品牌，也是亚太港式茶饮两大品牌之一。
        </p>
        <p>
          奉茶经过各种选型，最后选择和客如云合作，客如云不仅可以满足奉茶线上引流客户的需求、而且有专业的营销团队和营销系统，帮助奉茶建立自己的会员管理机制，清晰了解自己的会员画像，精准定位客户群体，策划各种会员营销活动，效果明显。并且上线微信自助点餐功能，实现收银自动交班，节约人工2名，增加30%的预定量，提升12%的会员储值量，实现门店人、财、物的一体化管理，节省企业的32%管理成本。
        </p>
        <p>3.九香牧谷“自家猪”连锁店</p>
        <p>
          九香牧谷“自家猪”连锁品牌门店，以自产自销、加盟连锁的形式经营发展，在吕梁、大同设有生产基地，对标吕梁山猪，2022年预计在太原开30-50家连锁零售门店，目前已开业8家。
        </p>
        <p>商户端要求：食品称重、智能收银、会员管理，菜品配送、智能支付</p>
        <p>解决方案：客如云电子秤+SAAS智能管理系统</p>
        <p>
          合作方式：易联提供商户及解决方案、商户和银行联合买单、商户开立银行结算账户、工资代发、信用卡开卡。
        </p>
        <p>银行合作价值：</p>
        <p>
          企业可开立合作银行账户或个人借记卡。连锁门店目前已开业的6家门店，年营业收入约900万元营收全部进入企业公户，考虑到商户采购等成本因素，以留存率40%计算，可带来360万元的结算性存款，按照年底开到30家门店测算，年营业收入约5400万，预计留存2160万结算性存款。
        </p>
        <p>
          可实现公司30余人代发工资落地合作行，每人每月平均5000元，全年预计代发规模约180万，信用卡开卡50张。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      id: 0,
      title: "",
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {
    this.title = sessionStorage.getItem("caseTitle");
    this.id = this.$route.query.id;
    this.breadcrumbName = "合作案例";
    this.pathurl = "/cases";
  },
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
}

.img_box img {
  display: block;
  width: 100%;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 10px 0;
  text-align: justify;
  /* text-indent: 2em; */
}
</style>
